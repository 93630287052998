<template>
  <section class="d-card" :class="getClass">
    <div v-if="ribbon" class="corner-ribbon" :class="getRibbonCLass">
      {{ ribbon }}
    </div>

    <div class="w-full title">{{ titlePrefix }}{{ title }}</div>

    <div class="w-full subtitle">{{ subtitle }}</div>
  </section>
</template>

<script>
export default {
  name: 'WalletCard',
  props: {
    title: {
      type: [String, Number],
      required: false,
    },
    titlePrefix: {
      type: [String, Number],
      required: false,
    },
    subtitle: {
      type: [String, Number],
      required: false,
    },
    textPosition: {
      type: String,
      required: false,
      default: 'left',
      validator(value) {
        return ['left', 'center', 'right'].includes(value)
      },
    },
    ribbon: {
      type: [String, Number],
      required: false,
    },
    ribbonColor: {
      type: String,
      required: false,
      default: 'orange',
      validator(value) {
        return [
          'white',
          'black',
          'grey',
          'blue',
          'green',
          'turquoise',
          'purple',
          'red',
          'orange',
          'yellow',
        ].includes(value)
      },
    },
    ribbonPosition: {
      type: String,
      required: false,
      default: 'top-right',
      validator(value) {
        return [
          'top-right',
          'top-left',
          'bottom-left',
          'bottom-right',
        ].includes(value)
      },
    },
    size: {
      type: String,
      required: false,
      default: 'sm',
      validator(value) {
        return ['sm', 'base'].includes(value)
      },
    },
    color: {
      type: String,
      required: false,
      default: 'yellow',
      validator(value) {
        return [
          'white',
          'black',
          'grey',
          'blue',
          'green',
          'turquoise',
          'purple',
          'red',
          'orange',
          'yellow',
        ].includes(value)
      },
    },
  },
  computed: {
    getClass() {
      return `${this.size} ${this.color} text-${this.textPosition}`
    },
    getRibbonCLass() {
      return `${this.ribbonColor} ${this.ribbonPosition}`
    },
  },
}
</script>

<style lang="scss" scoped>
.d-card {
  width: 100%;
  // margin: 5px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 1.25rem;

  // different sizes
  &.sm {
    height: 150px;
  }
  &.base {
    height: 190px;
  }

  // typography
  & .title {
    font-size: 37px;
    font-weight: bold;
    color: #080808;
  }
  & .subtitle {
    font-size: 19px;
    color: #080808;
  }
}

.corner-ribbon {
  width: 200px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 35px;
  letter-spacing: 1px;
  color: #fff;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);

  /* Different positions */
  &.top-left {
    top: 25px;
    left: -50px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  &.top-right {
    top: 25px;
    right: -50px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &.bottom-left {
    top: auto;
    bottom: 25px;
    left: -50px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &.bottom-right {
    top: auto;
    right: -50px;
    bottom: 25px;
    left: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  /* Custom styles */
  &.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  }
  &.sticky {
    position: fixed;
  }
}

.d-card,
.corner-ribbon {
  /* Colors */
  &.white {
    background: #f0f0f0;
    color: #555;
  }
  &.black {
    background: #333;
  }
  &.grey {
    background: #999;
  }
  &.blue {
    background: #39d;
  }
  &.green {
    background: #2c7;
  }
  &.turquoise {
    background: #1b9;
  }
  &.purple {
    background: #95b;
  }
  &.red {
    background: #e43;
  }
  &.orange {
    background: #ffa91f;
  }
  &.yellow {
    background-color: #fffc00;
  }
}
</style>
